import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';

export const Menu = () => {
    return (
        <Grid container
            sx={{
                typography: 'body1', 
                textAlign: 'center',
                justifyContent: 'center',
                paddingTop: '1rem',
                paddingBottom: '1rem',
                width: '90%',
                marginLeft: '5%',
                boxShadow: '10px 7px 18px -6px rgba(0,0,0,0.63)'
            }}>
            <Grid item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                xl={3}>
                <Link to='/'
                    style={{ textAlign: 'center', textDecoration: 'none' }}>
                    Home
                </Link>
            </Grid>
            <Grid item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                xl={3}>
                <Link to='/seguimiento'
                    style={{ textAlign: 'center', textDecoration: 'none' }}>
                    Seguimiento
                </Link>
            </Grid>
            <Grid item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                xl={3}>
                <Link to='/tyc'
                    style={{ textAlign: 'center', textDecoration: 'none' }}>
                    Terminos y Condiciones
                </Link>
            </Grid>
            <Grid item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                xl={3}>
                <Link to='contactanos'
                    style={{ textAlign: 'center', textDecoration: 'none' }}>
                    Contáctanos
                </Link>
            </Grid>
        </Grid>
    )
}
