import { Grid, Typography, Divider, Card, CardContent, TextField, Button } from '@mui/material';
import { useState } from 'react';
import { SweetAlert } from '../components/SweetAlert';
import { Comment } from '../models/Comment';
import { CommentService } from '../services/CommentService';

export const Contacts = () => {
    const commentService = CommentService();
    const sweetAlert = SweetAlert();
    const [comment, setComment] = useState<Comment>({} as Comment);
    const [isValidation, setIsValidation] = useState(false);
    
    const changeInput = (event: any) => {
        const {name, value} = event.target;
        setComment({...comment, [name]: value});
    }

    const createComment = async () => {
        setIsValidation(true);
        const response = await commentService.createComment(comment);
        // if (!response.isSuccess) {
        //     sweetAlert.showAlert(response.message, response.isSuccess)
        //     setIsValidation(false);
        // }
        // else {
        //     sweetAlert.showAlert(response.message, response.isSuccess)
        //     setComment({} as Comment);
        //     setIsValidation(false);
        // }
        sweetAlert.showAlert('Hemos recibido su comentario y/o sugerencia', true)
        setComment({} as Comment);
        setIsValidation(false);
    }
    return (
        <Grid container
            sx={{width: '90%', marginLeft: '5%', marginTop: '1%', marginBottom: '1%', paddingLeft: '5%', paddingRight: '5%'}}>
            <Grid item xs={12} sm={12} md={12}>
                <Typography align='center' variant='h2'>
                    Envianos tu comentario
                </Typography>
            </Grid>
            <Divider sx={{width: '100%', borderColor: '#2e3192', borderWidth: 'thin', marginTop: '1%', marginBottom: '1%'}} />
            <Grid item xs={12} sm={12} md={12}>
                <Card variant='outlined'
                    sx={{ boxShadow: '10px 7px 18px -6px rgba(0,0,0,0.63)', marginBottom: '2rem', paddingLeft: '25%' }}>
                    <CardContent>
                        <Grid container>
                            <Grid item xs={12} sm={8} sx={{marginBottom: '2%'}}>
                                <TextField type='text'
                                    placeholder='Ingrese nombre y apellido'
                                    name='nombreApellido'
                                    fullWidth
                                    required
                                    variant='outlined'
                                    onChange={ changeInput }
                                    disabled={ isValidation } />
                            </Grid>
                            <Grid item xs={12} sm={8} sx={{marginBottom: '2%'}}>
                                <TextField type='text'
                                    placeholder='Ingrese el correo personal'
                                    name='correo'
                                    fullWidth
                                    required
                                    variant='outlined'
                                    onChange={ changeInput }
                                    disabled={ isValidation } />
                            </Grid><Grid item xs={12} sm={8} sx={{marginBottom: '2%'}}>
                                <TextField type='email'
                                    placeholder='Ingrese su comentario y/o sugerencia'
                                    name='comentario'
                                    fullWidth
                                    required
                                    multiline
                                    rows={6}
                                    variant='outlined'
                                    onChange={ changeInput }
                                    disabled={ isValidation } />
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <Button onClick={ createComment }
                                    disabled={ isValidation }
                                    fullWidth
                                    variant='contained'
                                    color='primary'>
                                    Enviar comentario
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}
