import { CustomResponse } from '../models/CustomResponse';
import { Tracking } from '../models/Tracking';
import api from './ConfigService';

export const TrackingService = () => {
    const getSearchTracking = async (code: string): Promise<CustomResponse<Tracking>> => {
        let trackingData: CustomResponse<Tracking> = {isSuccess: false, responseCode: 0, message: '', data: {} as Tracking};
        await api.get(`Tracking/PorCodigo/${code}`)
        .then(response => {
            trackingData = response.data;
        }).catch(error => {
            trackingData = error;
        });
        return trackingData;
    }

    return {
        getSearchTracking
    }
}