import { Grid } from '@mui/material';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import { TrackingView } from './views/Tracking';
import { TermsAndConditions } from './views/TermsAndConditions';
import { Contacts } from './views/Contacts';
import { Home } from './views/Home';
import logo from './assets/images/banner_web.png';
import { Menu } from './components/Menu';
import { Footer } from './components/Footer';

export default function App() {
  return (
    <Grid container>
      <Grid item xs={12} >
        <img src={logo} alt='Galivica Express'
          style={{width: '90%', height: '300px', marginLeft: '5%', marginRight: '5%'}} />
        <BrowserRouter>
          <Menu />
          <Routes>
            <Route path='/*' element={ <Home /> } />
                <Route path='/seguimiento' element={ <TrackingView /> } />
                <Route path='/tyc' element={ <TermsAndConditions /> } />
                <Route path='/contactanos' element={ <Contacts /> } />
            </Routes>
        </BrowserRouter>
        <Footer />
      </Grid>
    </Grid>
  );
}