import { Grid, Typography, Divider } from '@mui/material';
import envios from '../assets/images/envios.jpeg';
import mision from '../assets/images/mision.jpeg';
import vision from '../assets/images/vison.jpeg';

export const Home = () => {
    return (
        <Grid container
            sx={{width: '90%', marginLeft: '5%', marginTop: '1%', marginBottom: '1%', paddingLeft: '5%', paddingRight: '5%'}}>
            <Grid item xs={2} sm={2} md={2}>
                <img src={envios} alt='Envios' style={{height: '120px'}} />
            </Grid>
            <Grid item xs={10} sm={10} md={10}>
                <Typography align='justify' variant='body1'>
                    Somos una empresa dedicada al envio de encomiendas Peru - Venezuela - Peru, nos destacamos por ofrecer 
                    un servicio de punta a punta desde la recepcion de la mercancia en nuestros locales hasta la entrega 
                    del mismo en el destino indicado por nuestros clientes, ofreciendo un acompañamiento en el seguimiento 
                    de los paquetes y ofreciendo una herramienta util para nuestros clientes, asi podran realizar el chequeo 
                    del estatus de sus envios.
                </Typography>
                <Typography align='justify' variant='body1'>
                    Siempre ofreciendo estandares de calidad en la atencion asi como en nuestros procesos, para cumplir en 
                    tiempo y forma con nuestros tiempos y SLA pautados en nuestra condiciones como empresa.
                </Typography>
            </Grid>
            <Divider sx={{width: '100%', borderColor: '#2e3192', borderWidth: 'thin', marginTop: '1%', marginBottom: '1%'}} />
            <Grid item xs={6} sm={6} md={6} sx={{paddingLeft: '20%'}}>
                <Typography align='justify' variant='body1'>
                    Nuestra Mision es optimizar las entregas de nuestros clientes mediante la implementacion de sistemas y 
                    nuestros conocimientos logisticos, manteniendo la calidad del servicio a un precio muy competitivo.
                </Typography>
            </Grid>
            <Grid item xs={2} sm={2} md={2}>
                <img src={mision} alt='Mision' style={{height: '120px', marginLeft: '50%'}} />
            </Grid>
            <Divider sx={{width: '100%', borderColor: '#2e3192', borderWidth: 'thin', marginTop: '1%', marginBottom: '1%'}} />
            <Grid item xs={2} sm={2} md={2} sx={{paddingLeft: '20%'}}>
                <img src={vision} alt='Vision' style={{height: '120px'}} />
            </Grid>
            <Grid item xs={6} sm={6} md={6} sx={{paddingLeft: '20%'}}>
                <Typography align='justify' variant='body1'>
                    Nuestra Vision es consolidarnos como un referente en cuanto a sistemas de envio, ofreciendo las soluciones 
                    personalizadas y profesionales.
                </Typography>
                <Typography align='justify' variant='body1'>
                    Manteniendo en constante comunicacion a nuestro cliente.
                </Typography>
            </Grid>
        </Grid>
    )
}
