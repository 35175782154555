import { AppBar, Grid, Toolbar, Typography } from '@mui/material';
// import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { FacebookOutlined, Instagram } from '@mui/icons-material';

const containerStyle = {
    width: '100rem',
    height: '20rem'
};

const center = {
    lat: -34.5971209,
    lng: -58.4418587
};

export const Footer = () => {
    return (
        <AppBar position="static"
            style={{ backgroundColor: '#2e3192', padding: '2rem', width: '90%', marginLeft: '5%' }}>
            <Toolbar>
                <Grid container
                    spacing={2}>
                    <Grid container
                        item
                        xl={6}
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}>
                        <Typography variant='h3'
                            color='secondary'
                            sx={{ textAlign: 'center', width: '100%' }}>
                            Encuéntranos
                        </Typography>
                        <Typography variant='body2'
                            color='secondary'
                            sx={{ width: '100%' }}>
                            Dirección: Av. Aviacion 950, Centro Comercial Gama, 1er Nivel,
                            {<br />}
                            Pasillo 21, Local 305, Gamarra - Distrito La Victoria
                            {<br />}
                            Telefonos: +51 953 512 877 / +51 941 593 642
                            {<br />}
                            Horarios de Atencion
                            {<br />}
                            Lunes a Sabados de 10 am a 7 pm
                            {<br />}
                            Domingos de 11 am a 5 pm
                        </Typography>
                        {/* <LoadScript googleMapsApiKey="AIzaSyDjDiaBqXmA2H9OKk-8Hm7DtedrBfa381Y" >
                            <GoogleMap mapContainerStyle={containerStyle}
                                center={center}
                                zoom={15} >
                                <Marker position={center} />
                            </GoogleMap>
                        </LoadScript> */}
                    </Grid>
                    <Grid item
                        xl={6}
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}>
                        <Typography variant='h3'
                            color='secondary'
                            sx={{ textAlign: 'center', marginBottom: '2rem' }}>
                            Siguenos en nuestras redes sociales
                        </Typography>
                            <FacebookOutlined sx={{
                                color: 'white',
                                paddingLeft: {xs: '3rem', sm: '3rem', md: '5rem', lg: '5rem', xl: '5rem'}, 
                                width: '10rem',
                                height: '10rem'
                            }}/>
                            <Instagram sx={{
                                color: 'white',
                                paddingLeft: {xs: '3rem', sm: '3rem', md: '5rem', lg: '5rem', xl: '5rem'},
                                width: '10rem',
                                height: '10rem'
                            }}/>
                    </Grid>
                    <Grid item
                        xl={12}
                        xs={12}
                        sx={{ marginTop: '2rem' }}>
                        <Typography variant='body1'
                            color='secondary'
                            sx={{ textAlign: 'center' }}>
                            ©2024 Galivica Express
                        </Typography>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    )
}