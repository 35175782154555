import { Grid, Typography, Divider, Card, CardContent, TextField, Button } from '@mui/material';
import { useState } from 'react';
import { SweetAlert } from '../components/SweetAlert';
import { Tracking } from '../models/Tracking';
import { TrackingService } from '../services/TrackingService';

export const TrackingView = () => {
    const trackingService = TrackingService();
    const sweetAlert = SweetAlert();
    const [tracking, setTracking] = useState<Tracking>({} as Tracking);
    const [trackingCode, setTrackingCode] = useState('');
    const [isValidation, setIsValidation] = useState(false);

    const changeInput = (event: any) => {
        setTrackingCode(event.target.value);
    }

    const searchPackage = async () => {
        setIsValidation(true);
        const response = await trackingService.getSearchTracking(trackingCode);
        if (!response.isSuccess) {
            sweetAlert.showAlert(response.message, response.isSuccess)
            setIsValidation(false);
            setTracking({} as Tracking);
        }
        else {
            setTracking(response.data);
            setIsValidation(false);
        }
    }

    return (
        <Grid container
            sx={{width: '90%', marginLeft: '5%', marginTop: '1%', marginBottom: '1%', paddingLeft: '5%', paddingRight: '5%'}}>
            <Grid item xs={12} sm={12} md={12}>
                <Typography align='center' variant='h2'>
                    Monitorea el estado de tu paquete
                </Typography>
            </Grid>
            <Divider sx={{width: '100%', borderColor: '#2e3192', borderWidth: 'thin', marginTop: '1%', marginBottom: '1%'}} />
            <Grid item xs={12} sm={12} md={12}>
                <Card variant='outlined'
                    sx={{ boxShadow: '10px 7px 18px -6px rgba(0,0,0,0.63)', marginBottom: '2rem', paddingLeft: '20%' }}>
                    <CardContent>
                        <Grid container>
                            <Grid item xs={12} sm={6}>
                                <TextField type='text'
                                    placeholder='Ingrese el codigo del paquete'
                                    name='codigo'
                                    fullWidth
                                    required
                                    variant='outlined'
                                    onChange={ changeInput }
                                    disabled={ isValidation } />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button sx={{ marginTop: '0.5rem', marginLeft: '1rem' }}
                                    onClick={ searchPackage }
                                    disabled={ isValidation }
                                    variant='contained'
                                    color='primary'>
                                    Buscar paquete
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            {tracking.numeroControl !== undefined ?
                <Grid item xs={12} sm={12} md={12}>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <Typography align='center' variant='h4'>
                                Resultados de la busqueda
                            </Typography>
                        </Grid>
                    </Grid>
                    <Card variant='outlined'
                        sx={{ boxShadow: '10px 7px 18px -6px rgba(0,0,0,0.63)', marginBottom: '2rem', paddingLeft: '10%' }}>
                        <CardContent>
                            <Grid container sx={{marginTop: '2%'}}>
                                <Grid item xs={12} sm={2}>
                                    Codigo:
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <strong>{tracking.numeroControl}</strong>
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    Estado del paquete:
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <strong>{tracking.estatus}</strong>
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    Fecha de creacion:
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <strong>{new Date(tracking.fechaCreacion).toLocaleDateString('en-GB')}</strong>
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    Fecha de entrega:
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <strong>{new Date(tracking.fechaEntrega).toLocaleDateString('en-GB')}</strong>
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    Remitente:
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <strong>{tracking.remitente.nombre} {tracking.remitente.apellido}</strong>
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    Destinatario:
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <strong>{tracking.destinatario.nombre} {tracking.destinatario.apellido}</strong>
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    Origen:
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <strong>{tracking.almacenOrigen}</strong>
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    Destino:
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <strong>{tracking.almacenDestino}</strong>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                : ''
            }
        </Grid>
    )
}
