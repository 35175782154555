import { Grid, Typography, Divider } from '@mui/material';

export const TermsAndConditions = () => {
    return (
        <Grid container
            sx={{width: '90%', marginLeft: '5%', marginTop: '1%', marginBottom: '1%', paddingLeft: '5%', paddingRight: '5%'}}>
            <Grid item xs={12} sm={12} md={12} sx={{marginBottom: '2%'}}>
                <Typography variant='h1' align='center'>Terminos y condiciones</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} sx={{marginBottom: '1%'}}>
                <Typography variant='h3' align='left'>Sobre el estatuto de los envios</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <Typography align='justify' variant='body1'>
                    <strong>RECIBIDO:</strong> Esto significa que hemos recibido su paquete en la oficina comercial.
                </Typography>
                <Typography align='justify' variant='body1'>
                    <strong>EMPACADO:</strong> El paquete ha sido embalado en nuestra oficina comercial y sera enviado al operador logistigo de aduanas PERU.
                </Typography>
                <Typography align='justify' variant='body1'>
                    <strong>ADUANA:</strong> El paquete esta entregado en aduana PERU donde se encargan de la auditoria, verificacion, montos y aranceles 
                    aduanales. Lapso de un(1) dia, sin revision especial de aduana, si se presentael caso de canal rojo los lapsos cambian a siete(7) dias 
                    habiles.
                </Typography>
                <Typography align='justify' variant='body1'>
                    <strong>EMBARCADO:</strong> La carga pasa todos los procesos de aduana y esta en transito LIMA - VENEZUELA. Lapso de tres(3) a siete(7) 
                    dias para que conecte a VENEZUELA, sin cambios de aerolinea.
                </Typography>
                <Typography align='justify' variant='body1'>
                    <strong>ARRIBO:</strong> La carga llega a VENEZUELA y esta en proceso de liberacion. Lapso de aduana VENEZUELA uno(1) a cinco(5) dias 
                    habiles sin revision especial de aduana y si se presenta el caso, los lapsos cambian hasta 30 dias habiles.
                </Typography>
                <Typography align='justify' variant='body1'>
                    <strong>RECIBIDO EN LOGISTICA:</strong> La carga llega al centro de distribucion para su revision y auditoria.
                </Typography>
                <Typography align='justify' variant='body1'>
                    <strong>TRANSFERIDO:</strong> Cada paquete ha sido enviado a la agencia ZOOM que hara entrega en la agencia destino. Lapso de tres(3) 
                    a quince(15) dias dependiendo del destino.
                </Typography>
                <Typography align='justify' variant='body1'>
                    <strong>DISTRIBUCION:</strong> El paquete se encuentra con el agente movil en Caracas para realizar la entrega. Lapso de tres(3) a 
                    cinco(5) dias para entregar personalmente al destinatario.
                </Typography>
                <Typography align='justify' variant='body1'>
                    <strong>ENTREGADO:</strong> El paquete se entrega al destinatario o retirado en ZOOM.
                </Typography>
            </Grid>
            <Divider sx={{width: '100%', borderColor: '#2e3192', borderWidth: 'thin', marginTop: '1%', marginBottom: '1%'}} />
        </Grid>
    )
}
