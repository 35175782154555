import {createTheme} from '@mui/material';
import '../fonts/myriad-set-pro_text.woff';

const Theme = createTheme({
    palette: {
        text: {
            primary: '#424958'
        },    
        primary: {
            main: '#2e3192'
        },
        secondary: {
            main: '#ffffff'
        },
        info: {
            main: '#17c1e8'
        },
        success: {
            main: '#585dfb'
        },
        warning: {
            main: '#fbcf33'
        },
        error: {
            main: '#ea0606'
        }
    },
    typography: {
        body1: {
            fontSize: '1rem',
            fontFamily: 'Myriad'
        },
        body2: {
            fontSize: '0.75rem',
            fontFamily: 'Myriad'
        },
        h1: {
            fontSize: '4rem',
            fontFamily: 'Myriad'
        },
        h2: {
            fontSize: '3rem',
            fontFamily: 'Myriad'
        },
        h3: {
            fontSize: '2rem',
            fontFamily: 'Myriad'
        }
    }
});

export default Theme;