import { CustomResponse } from '../models/CustomResponse';
import { Comment } from '../models/Comment';
import api from './ConfigService';

export const CommentService = () => {
    const createComment = async (comment: Comment): Promise<CustomResponse<boolean>> => {
        let flat: CustomResponse<boolean> = {} as CustomResponse<boolean>;
        await api.post(`Comentario`, comment)
        .then(response => {
            flat = response.data;
        }).catch(error => {
            flat = error;
        });
        return flat;
    }

    return {
        createComment
    }
}